<template>
  <div>
    <div class="e-breadcrumb">销售事业部业绩统计</div>
    <div class="order-table-wrapper" style="padding-top: 0px">
      <div class="e-card">
        <div class="e-card-body" style="padding-top: 30px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">门店</span>
              <el-select
                size="small"
                clearable
                v-model="departmentCode"
                placeholder="选择门店"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.departmentName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span class="v-form-label">日期</span>
              <el-date-picker
                size="small"
                v-model="cxDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
              <span class="v-form-label">部门</span>
              <el-select
                size="small"
                clearable
                v-model="departmentCode"
                placeholder="选择部门"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.departmentName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!--<span class="v-form-label" style="margin-right: 10px">员工级别</span>-->
              <!--<div class="e-tabs-wrapper">-->
              <!--<div class="e-tabs-item " :class="{'active':tabIndex==index}" v-for="(item,index) in userGradeList"-->
              <!--@click="switchTable(index,item.id)">{{item.gradeName}}-->
              <!--</div>-->
              <!--<div class="e-tabs-item" :class="{ active: tabIndex == -1 }" @click="switchTable(-1)">全部</div>-->
              <!--</div>-->
              <el-row type="flex" justify="end">
                <el-button
                  size="small"
                  class="e-button"
                  style="margin-left: 28px"
                  type="primary"
                  @click="requestSearch()"
                >
                  查询
                </el-button>
              </el-row>
            </div>
          </div>

          <div id="table-con">
            <table class="table-head" width="80%">
              <thead>
              <tr>
                <th class="headerTable" rowspan="2">员工名称</th>
                <th class="headerTable" rowspan="2">总客数</th>
                <th class="headerTable" rowspan="2">总业绩</th>
                <th class="headerTable" rowspan="2">平均单价</th>
                <th class="headerTable" rowspan="2">指定客数</th>
                <th class="headerTable" rowspan="2">指定业绩</th>
                <th class="headerTable" rowspan="2">非指定业绩</th>
              </tr>
              </thead>
            </table>
            <div class="timeBody">
              <table>
                <tbody>
                <template v-for="(item, index) in list">
                  <tr v-for="(m, i) in item.itemDetaileds" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                    <!-- 第1列每个学生只需要展示1次 -->
                    <td class="headerTable" v-if="i == 0" :rowspan="item.itemDetaileds.length">
                      {{ item.nickName }}
                    </td>
                    <td class="headerTable" v-if="i == 0" :rowspan="item.itemDetaileds.length">
                      {{ item.orderNum}}
                    </td>
                    <td class="headerTable" v-if="i == 0" :rowspan="item.itemDetaileds.length">
                      {{ item.totalAmount }}
                    </td>
                    <td class="headerTable" v-if="i == 0" :rowspan="item.itemDetaileds.length">
                      {{ item.averageAmount }}
                    </td>
                    <!-- 第2列每个学生只需要展示1次 -->
                    <td class="headerTable" v-if="i == 0" :rowspan="item.itemDetaileds.length">
                      {{ item.appointNum }}
                    </td>
                    <td class="headerTable" v-if="i == 0" :rowspan="item.itemDetaileds.length">
                      {{ item.appointAmount }}
                    </td>
                    <td class="headerTable" v-if="i == 0" :rowspan="item.itemDetaileds.length">
                      {{ item.unspecifiedAmount }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabIndex: -1,
        gradeId: '',
        storeList: [],
        userGradeList: [],
        cxDate: [],
        departmentCode: "",

        list: [],
        peo: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1,
        },
      };
    },
    async created() {
      this.getStoreList();
      this.getUserGrade()
      this.requestSearch();
    },
    methods: {
      async getUserGrade() {
        let res = await this.$get("/admin/getUserGradeList")
        if (res.code == 200) {
          this.userGradeList = res.list
        }
      },
      switchTable(index, id) {
        this.gradeId = id
        this.tabIndex = index
      },
      async getStoreList() {
        let res = await this.$get("/admin/getStoreList");
        this.storeList = res;
      },

      handleCurrentChange(page) {
        this.requestSearch(page);
      },

      async requestSearch(page) {
        let currentPage = page || 1;
        let staDate = "";
        let endDate = "";
        if (this.cxDate && this.cxDate.length > 0) {
          staDate = this.cxDate[0];
          endDate = this.cxDate[1];
        }
        let data = {
          staDate: staDate,
          endDate: endDate,
          departmentCode: this.departmentCode,
          isDel: "0",
          orderType: '0',
          size: this.size == "" ? "10" : this.size,
          currentPage,
        };
        let res = await this.$get("/admin/getEmpAchievement", data);
        const list = res.dataSet.list;
        this.list = list;
        this.pagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pages: res.dataSet.pages,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }

  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }

  .box-card {
    width: 18%;
    margin-right: 20px;
    display: inline-block;
  }

  .box-cards {
    width: 35%;
    margin-right: 20px;
  }

  .text-item {
    width: 100%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      span {
        margin-right: 5px;
      }
    }
  }

  .text-items {
    width: 50%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      span {
        margin-right: 5px;
      }
    }
    .item-h4 {
      height: 20px;
      font-size: 12px;
    }
  }

  #table-con {
    table {
      width: 100%;
      border: 1px solid #ccc;
      border-collapse: collapse;
      text-align: center;
    }
    thead th {
      text-align: center;
      /*background-color: #fafafa;*/
    }
    th,
    td {

      border: 1px solid #e9eaec;
      // line-height: 30px;
      text-align: center;
      vertical-align: middle !important;
    }
    .headerTable {
      width: 50px;
      height: 35px;
      font-size: 10px;
    }
    .minxi {
      width: 30px;
      height: 35px;
      font-size: 14px;
    }
  }

  .headerTables {
    height: 30px;
  }

  .minxis {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .off td{
  background-color: #fff;
  border-right: 1px solid #dddddd !important;
}
.on td{
  background-color: #ececea;
  border-right: 1px solid #dddddd !important;
}
</style>
